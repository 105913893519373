import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 6rem 2rem;
  background: ${props => props.theme.colors.accent};
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  color: ${props => props.theme.colors.primary};
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const ServiceCard = styled.div`
  background: ${props => props.theme.colors.white};
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ServiceTitle = styled.h3`
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;

const ServiceDescription = styled.p`
  color: ${props => props.theme.colors.lightText};
`;

const Services = () => {
  const services = [
    {
      title: "Strategic Technology Planning",
      description: "Comprehensive assessment and roadmap development to align technology initiatives with organizational goals."
    },
    {
      title: "Implementation Leadership",
      description: "Expert guidance and management of complex healthcare technology implementations and integrations."
    },
    {
      title: "Executive Consulting",
      description: "Strategic advisory services for C-suite executives on technology investment and organizational transformation."
    },
    {
      title: "Process Optimization",
      description: "Streamlining workflows and improving operational efficiency through technology-enabled solutions."
    },
    {
      title: "Change Management",
      description: "Structured approach to transitioning organizations through technological and operational changes."
    },
    {
      title: "Performance Analytics",
      description: "Data-driven insights and metrics to measure and improve organizational performance."
    }
  ];

  return (
    <Section id="services">
      <Container>
        <Title>Our Services</Title>
        <Grid>
          {services.map((service, index) => (
            <ServiceCard key={index}>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDescription>{service.description}</ServiceDescription>
            </ServiceCard>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default Services; 