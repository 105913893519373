import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 3rem;
  opacity: 0.9;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Input = styled.input`
  padding: 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
`;

const TextArea = styled.textarea`
  padding: 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  min-height: 150px;
  resize: vertical;
`;

const Button = styled.button`
  background: ${props => props.theme.colors.secondary};
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background: ${props => props.theme.colors.primary};
  }
`;

const StyledSection = styled.section`
  padding: 6rem 2rem;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
`;

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
  };

  return (
    <StyledSection id="contact">
      <Container>
        <Title>Let's Work Together</Title>
        <Subtitle>
          Ready to transform your healthcare organization? Contact us to discuss how we can help.
        </Subtitle>
        <Form onSubmit={handleSubmit}>
          <Input type="text" placeholder="Name" required />
          <Input type="email" placeholder="Email" required />
          <Input type="text" placeholder="Organization" required />
          <TextArea placeholder="Tell us about your project or requirements" required />
          <Button type="submit">Send Message</Button>
        </Form>
      </Container>
    </StyledSection>
  );
};

export default Contact; 