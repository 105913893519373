import React from 'react';
import styled from 'styled-components';
import forrestImage from '../images/forrest.JPG';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const Content = styled.div``;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const Title = styled.h2`
  color: ${props => props.theme.colors.primary};
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
`;

const Text = styled.p`
  color: ${props => props.theme.colors.lightText};
  margin-bottom: 1rem;
  font-size: 1.1rem;
`;

const StyledSection = styled.section`
  padding: 6rem 2rem;
  background: ${props => props.theme.colors.white};
`;

const About = () => {
  return (
    <StyledSection id="about">
      <Container>
        <Grid>
          <Content>
            <Title>Two Decades of Excellence</Title>
            <Text>
              Since 2005, our team has been at the forefront of healthcare technology transformation, 
              delivering exceptional results for healthcare organizations across the nation.
            </Text>
            <Text>
              Our expertise spans the entire healthcare technology ecosystem, from strategic planning and implementation 
              to optimization and leadership development. We pride ourselves on our ability to bridge the gap between 
              complex technical solutions and real-world business challenges.
            </Text>
            <Text>
              With a proven track record of successful implementations and a deep understanding of healthcare operations, 
              we bring unparalleled experience to every engagement.
            </Text>
          </Content>
          <StyledImage src={forrestImage} alt="Forrest LaRocca" />
        </Grid>
      </Container>
    </StyledSection>
  );
};

export default About; 