import React from 'react';
import styled from 'styled-components';

const FooterSection = styled.footer`
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  padding: 4rem 2rem 2rem;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Logo = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
`;

const Title = styled.h4`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

const Link = styled.a`
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme.colors.white};
  }
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0.5rem;
`;

const Copyright = styled.div`
  text-align: center;
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
`;

const Footer = () => {
  return (
    <FooterSection>
      <Container>
        <Column>
          <Logo>LaRocca Consulting Group</Logo>
          <Text>
            Transforming healthcare organizations through innovative technology 
            solutions and strategic leadership for over two decades.
          </Text>
        </Column>
        <Column>
          <Title>Quick Links</Title>
          <Link href="#about">About Us</Link>
          <Link href="#services">Our Services</Link>
          <Link href="#expertise">Areas of Expertise</Link>
          <Link href="#contact">Contact</Link>
        </Column>
        <Column>
          <Title>Contact Info</Title>
          <ContactItem>
            <i className="fas fa-phone"></i>
            <Link href="tel:+1234567890">+1 (234) 567-890</Link>
          </ContactItem>
          <ContactItem>
            <i className="fas fa-envelope"></i>
            <Link href="mailto:contact@laroccaconsulting.com">
              contact@laroccaconsulting.com
            </Link>
          </ContactItem>
          <ContactItem>
            <i className="fas fa-map-marker-alt"></i>
            <Text>123 Business Avenue, Suite 100<br />New York, NY 10001</Text>
          </ContactItem>
        </Column>
      </Container>
      <Copyright>
        © {new Date().getFullYear()} LaRocca Consulting Group LLC. All rights reserved.
      </Copyright>
    </FooterSection>
  );
};

export default Footer; 