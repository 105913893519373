import React from 'react';
import styled from 'styled-components';

const HeroSection = styled.section`
  height: 100vh;
  background: linear-gradient(rgba(0, 51, 102, 0.8), rgba(0, 51, 102, 0.8));
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 2rem;
`;

const HeroContent = styled.div`
  color: ${props => props.theme.colors.white};
  max-width: 800px;
`;

const HeroTitle = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: 2.5rem;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

const Hero = () => {
  return (
    <HeroSection>
      <HeroContent>
        <HeroTitle>Excellence in Healthcare Technology Consulting</HeroTitle>
        <HeroSubtitle>
          Our team has over twenty years of experience transforming healthcare organizations through innovative technology solutions and strategic leadership
        </HeroSubtitle>
      </HeroContent>
    </HeroSection>
  );
};

export default Hero; 