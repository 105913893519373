export const theme = {
  colors: {
    primary: '#1a365d',    // Dark blue
    secondary: '#2b6cb0',  // Medium blue
    accent: '#4299e1',     // Light blue
    white: '#ffffff',
    text: '#2d3748',      // Dark gray
    lightText: '#4a5568',  // Medium gray
    background: '#f7fafc', // Light gray background
  },
  fonts: {
    heading: "'Montserrat', sans-serif",
    body: "'Open Sans', sans-serif",
  },
  breakpoints: {
    mobile: '480px',
    tablet: '768px',
    desktop: '1024px',
  }
};

export default theme; 