import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 6rem 2rem;
  background: ${props => props.theme.colors.white};
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  color: ${props => props.theme.colors.primary};
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const ExpertiseCard = styled.div`
  padding: 2rem;
  border-left: 4px solid ${props => props.theme.colors.secondary};
  background: ${props => props.theme.colors.accent};
  border-radius: 0 8px 8px 0;
`;

const ExpertiseTitle = styled.h3`
  color: ${props => props.theme.colors.primary};
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const ExpertiseList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ExpertiseItem = styled.li`
  color: ${props => props.theme.colors.lightText};
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;

  &:before {
    content: "•";
    color: ${props => props.theme.colors.secondary};
    position: absolute;
    left: 0;
  }
`;

const Expertise = () => {
  const expertiseAreas = [
    {
      title: "Healthcare Information Systems",
      items: [
        "Electronic Health Records (EHR)",
        "Practice Management Systems",
        "Revenue Cycle Management",
        "Clinical Decision Support",
        "Health Information Exchange"
      ]
    },
    {
      title: "Digital Transformation",
      items: [
        "Cloud Migration Strategy",
        "Data Analytics & Business Intelligence",
        "Mobile Health Solutions",
        "Telehealth Implementation",
        "Digital Patient Engagement"
      ]
    },
    {
      title: "Operational Excellence",
      items: [
        "Workflow Optimization",
        "Resource Management",
        "Quality Metrics & Reporting",
        "Regulatory Compliance",
        "Cost Reduction Initiatives"
      ]
    },
    {
      title: "Strategic Leadership",
      items: [
        "IT Governance",
        "Change Management",
        "Vendor Selection & Management",
        "Team Development & Training",
        "Strategic Planning & Execution"
      ]
    }
  ];

  return (
    <Section id="expertise">
      <Container>
        <Title>Areas of Expertise</Title>
        <Grid>
          {expertiseAreas.map((area, index) => (
            <ExpertiseCard key={index}>
              <ExpertiseTitle>{area.title}</ExpertiseTitle>
              <ExpertiseList>
                {area.items.map((item, itemIndex) => (
                  <ExpertiseItem key={itemIndex}>{item}</ExpertiseItem>
                ))}
              </ExpertiseList>
            </ExpertiseCard>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default Expertise; 